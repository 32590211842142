import React from 'react';
import StyleProvider from '@sendsprint/ui-react/dist/providers/StyleProvider/StyleProvider';
import { HelmetProvider } from 'react-helmet-async';
import { ToastProvider } from '@src/contexts/toast-context';
import QueryProvider from '@src/contexts/query-context';
import AuthProvider from '@src/contexts/auth-context';
import { Outlet } from 'react-router-dom';
import AppLayoutInner from './components/appLayoutInner';

const AppLayout = () => {
  return (
    <HelmetProvider>
      <StyleProvider>
        <ToastProvider autoDismiss={true} placement="bottom-right">
          <QueryProvider>
            <AuthProvider>
              <AppLayoutInner>
                <Outlet />
              </AppLayoutInner>
            </AuthProvider>
          </QueryProvider>
        </ToastProvider>
      </StyleProvider>
    </HelmetProvider>
  );
};

export default AppLayout;
