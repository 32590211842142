/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError, AxiosResponse } from 'axios';
import ClientApi from '@src/types/client';
import { useMutation, UseQueryOptions, UseMutationOptions, useQuery } from 'react-query';

import {
  loginService,
  ForgetPasswordService,
  ResetPasswordService,
  refreshTokenService,
  logoutService
} from '@src/services/auth-service';
import Api from '@sendsprint/api-types';
// import { useNavigate } from 'react-router-dom';
export const useLogin = (
  options?: Omit<
    UseMutationOptions<
      ClientApi.General.LoggedInUser,
      AxiosError<Api.Endpoint.Response.ErrorResponse>,
      ClientApi.Account.Login.Request
    >,
    'mutationKey' | 'mutationFn'
  >
) => {
  return useMutation<
    ClientApi.General.LoggedInUser,
    AxiosError<Api.Endpoint.Response.ErrorResponse>,
    ClientApi.Account.Login.Request
  >('login', loginService, options);
};

export const useForgotPassword = (
  options?: Omit<
    UseMutationOptions<
      ClientApi.General.ForgotPasswordInUser,
      AxiosError<Api.Endpoint.Response.ErrorResponse>,
      ClientApi.Account.ForgotPassword.Request
    >,
    'mutationKey' | 'mutationFn'
  >
) => {
  return useMutation<
    ClientApi.General.ForgotPasswordInUser,
    AxiosError<Api.Endpoint.Response.ErrorResponse>,
    ClientApi.Account.ForgotPassword.Request
  >('forgotPassword', ForgetPasswordService, options);
};

export const useResetPassword = (
  options?: Omit<
    UseMutationOptions<
      ClientApi.General.ResetPasswordUser,
      AxiosError<Api.Endpoint.Response.ErrorResponse>,
      ClientApi.Account.ResetPassword.Request
    >,
    'mutationKey' | 'mutationFn'
  >
) => {
  return useMutation<
    ClientApi.General.ResetPasswordUser,
    AxiosError<Api.Endpoint.Response.ErrorResponse>,
    ClientApi.Account.ResetPassword.Request
  >('resetPassword', ResetPasswordService, options);
};

export const useRefresToken = (options?: UseQueryOptions<any>) => {
  return useQuery<any>({
    queryKey: ['refreshToken'],
    queryFn: () => refreshTokenService(),
    ...options
  });
};

// export const useLogout = (options?: UseQueryOptions<any>) => {
//   return useQuery<any>({
//     queryKey: ['logout'],
//     queryFn: () => logoutService(),
//     ...options
//   });
// };

export const useLogout = (
  options?: Omit<
    UseMutationOptions<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      boolean,
      AxiosError<Api.Endpoint.Response.ErrorResponse>
    >,
    'mutationKey' | 'mutationFn'
  >
) => {
  return useMutation<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    boolean,
    AxiosError<Api.Endpoint.Response.ErrorResponse>
  >('logout', logoutService, options);
};
