import React from 'react';
import { useAccount } from '@src/contexts/auth-context';
import { Path } from '@src/navigations/routes';
import { Navigate, Outlet } from 'react-router-dom';
import Box from '@sendsprint/ui-react/dist/components/Box';
import { ReactComponent as Logo } from '@sendsprint/design-system/brand/logos/logo-full-green.svg';
import Container from '@src/components/container';

const AuthLayout = () => {
  const { isLoggedIn } = useAccount();

  if (isLoggedIn) {
    return <Navigate to={Path.Dashboard} replace />;
  }

  return (
    <Box>
      <Box className="ss-py-7 ss-sticky ss-top-0">
        <Container>
          <Logo className="ss-h-10 ss-w-32 md:ss-w-56 md:ss-h-10" role="presentation" />
        </Container>
      </Box>
      <Box as="main" className="ss-pt-10 md:ss-pt-20 ss-pb-10">
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
