import { getEnvData } from '@src/utils/env';
import axios from 'axios';
export type { AxiosRequestConfig as RequestConfig } from 'axios';

const ENV_DATA = getEnvData();

export const userBaseURL = ENV_DATA.API_BASE_URL;
export const userStagingBaseURL = ENV_DATA.API_STAGING_BASE_URL;

export const UserAxiosConfig = axios.create({
  baseURL: userBaseURL,
  timeout: 60000,
  showToastOnError: true
});

export const UserStagingAxiosConfig = axios.create({
  baseURL: userStagingBaseURL
});
